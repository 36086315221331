import React, {useState} from "react";
import {Link} from "react-router-dom";
import "../../styles/home.css";
import {Toolbar, Button} from "@material-ui/core";
import AFTLogo from "../../images/aft-logo.png";
// import Tooltip from "@material-ui/core/Tooltip";
import {useHistory} from "react-router";
import {useSelector, useDispatch} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import config from "../../app.config";
import apiClient from "../../utils/apiClient";

const Header = () => {
	let history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const state: any = useSelector((state) => state),
		{auth} = state,
		dispatch = useDispatch();

	const handleDocumentation = () => {
		history.push("/documentation");
	};
	const handleFAQ = () => {
		history.push("/faqs");
	};

	const handleAbout = () => {
		history.push("/about");
	};

	const handleLogin = () => {
		history.push("/login");
	};

	const handleRegister = () => {
		history.push("/register");
	};

	const handleLogout = async () => {
		await apiClient.get("logout");
		dispatch({type: "AUTH_LOGOUT"});
	};

	const showAccountMenu = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const loadUserProfile = () => {
		alert("FIXME");
	};
	/* eslint-disable */
	const styles: { [name: string]: React.CSSProperties } = {
		container: {
			marginTop: -80,
			position: "fixed",
			zIndex: 2,
			width: "-webkit-fill-available",
			// border: "2px solid black",
			// borderBottom: "4px solid black",
		},
	};
	return (
		<div>
			<Toolbar className="banner" style={styles.container}>
				<div className="titleSection">
					<Link to="/">
						<img
							src={AFTLogo}
							alt="AFT"
							title="American Farmland Trust"
							style={{ maxWidth: "60px" }}
						/>
					</Link>

					<span>
						<Link
							to="/"
							style={{
								paddingLeft: "20px",
								color: "inherit",
								textDecoration: "none",
							}}
						>
							Soil Health Economic Calculator
						</Link>
					</span>
				</div>

				<div className="authSection">
					{!auth.user ? (
						<div>
							<Button
								onClick={handleLogin}
								style={{ height: "40px", color: "inherit" }}
							>
								Login
							</Button>
							<Button
								onClick={handleRegister}
								style={{ height: "40px", color: "inherit" }}
							>
								Register
							</Button>
							<Button
								style={{
									height: "40px",
									color: "inherit",
									paddingRight: "20px",
								}}
								onClick={() => handleAbout()}
							>
								About
							</Button>
							{/* <a
								href={config.faqUrl}
								target="_blank"
								rel="noreferrer"
								style={{color: "inherit", textDecoration: "none"}}
							>
								<Button
									style={{
										height: "40px",
										color: "inherit",
										paddingRight: "20px",
									}}
								>
									FAQ
								</Button>
							</a> */}
							{/* <Tooltip title="Troubleshooting steps and FAQs">
								<Button style={{height: "40px", color: "inherit"}}>
									<a href={config.faqUrl} target="_blank" rel="noreferrer"
									   style={{color: "inherit", textDecoration: "none"}}
										// onlyActiveOnIndex
									>Need Help ?</a>
								</Button>
							</Tooltip> */}
						</div>
					) : (
						<div>
							<div className="accountSection">
								<Button
									style={{
										height: "40px",
										color: "inherit",
										fontSize: "1.125rem",
										paddingRight: "20px",
										fontWeight: 600,
										textTransform: "capitalize",
									}}
									onClick={() => handleDocumentation()}
								>
									References
								</Button>
								<Button
									style={{
										height: "40px",
										color: "inherit",
										fontSize: "1.125rem",
										paddingRight: "20px",
										fontWeight: 600,
										textTransform: "capitalize",
									}}
									onClick={() => handleAbout()}
								>
									About
								</Button>
								{/* <a
									href={config.faqUrl}
									target="_blank"
									rel="noreferrer"
									style={{ color: "inherit", textDecoration: "none" }}
								> */}
								<Button
									style={{
										height: "40px",
										color: "inherit",
										fontSize: "1.125rem",
										paddingRight: "20px",
										fontWeight: 600,
										textTransform: "capitalize",
									}}
									onClick={() => handleFAQ()}
								>
									FAQ
								</Button>
								{/* </a> */}
								<IconButton
									onClick={showAccountMenu}
									style={{ color: "white" }}
								>
									<AccountBoxIcon style={{ fontSize: 40 }} />

									<ArrowDropDownIcon style={{ padding: 0, margin: 0 }} />
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorEl}
									getContentAnchorEl={null}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center",
									}}
									open={Boolean(anchorEl)}
									onClose={handleMenuClose}
									MenuListProps={{ disablePadding: true }}
								>
									<MenuItem
										onClick={handleMenuClose}
										style={{
											fontSize: "12px",
											fontWeight: 700,
											maxWidth: "300px",
											cursor: "default",
										}}
									>
										<span
											style={{
												display: "contents",
												fontSize: "12px",
												fontWeight: 400,
											}}
										>
											Signed in as
										</span>
										<br />
										{auth.user.email}
									</MenuItem>

									<Divider />
									<MenuItem dense={true} disabled /*onClick={loadUserProfile}*/>
										Account Settings
									</MenuItem>

									<MenuItem onClick={handleMenuClose} dense={true}>
										<a
											href={"/about"} //   href={config.faqUrl}
											target="_blank"
											rel="noreferrer"
											style={{
												color: "inherit",
												width: "100%",
												textDecoration: "none",
											}}
											// onlyActiveOnIndex
										>
											Help & Support
										</a>
									</MenuItem>
									<Divider />
									<MenuItem onClick={handleLogout}>Logout</MenuItem>
								</Menu>
							</div>
						</div>
					)}
				</div>
			</Toolbar>
		</div>
	);
};

export default Header;
