import React from "react";
import {Link} from "react-router-dom";

interface Info {
	key: any;
	href: any;
	children: any;
}

export default function CustomLink({key, href, children, ...props}: Info) {
	if (href.startsWith("http")) {
		//outside routes
		return <Link to={href}>{href}</Link>;
		//eslint-disable-next-line
	} else {
		return (
			//local site routes
			<Link {...props} to={href}>
				{children}
			</Link>
		);
	}
}
