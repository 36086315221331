import React, {useState} from "react";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {apiClient} from "../utils/axiosClients";
import {toUsd} from "../utils/common";
/* eslint-disable */
const StyledTableCell = withStyles((theme) => ({
	head: {
		padding: 4,
		backgroundColor: "#455A64",
		color: theme.palette.common.white,
	},
	body: {
		padding: 12,
		fontSize: 16,
		"& input": {
			border: "solid 1px",
			borderColor: "#455A64",
			width: "75%",
			lineHeight: "150%",
		},
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		margin: 0,
	},
	budgetSummaryGrid: {
		maxWidth: "1250px",
		marginLeft: "20px",
		padding: "17px 4px",
	},
});

interface props {
	fieldId: number | null;
}

interface Field {
	id: number;
	name: string;
	total_acres: number;
	total_cc_acres: number;
}

interface RevenueResults {
	title: string;
	health: string;
	benchmark: number;
	current: number;
	total_change: number;
	change_per_acre: number | null;
}
interface CostResults {
	title: string;
	health: string;
	benchmark: number;
	current: number;
	total_change: number;
	change_per_acre: number | null;
}
interface TotalResults {
	title: string;
	benchmark: number;
	current: number;
	total_change: number;
	change_per_acre: number | null;
}
interface SumResults {
	title: string;
	value: number | null;
}

const PartialBudgetSummary = ({ fieldId }: props) => {
	const classes = useStyles();

	const DefaultRevenueResults = [
		{
			title: "Revenue due to Crop Rotation",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
	];
	const DefaultCostResults = [
		{
			title: "Cash Crop Seeds",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
		{
			title: "Tillage",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
		{
			title: "Fertilizer Application",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
		{
			title: "Herbicide",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
		{
			title: "Fungicide",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
		{
			title: "Insecticide",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
		{
			title: "Cover Crop Seeds",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
		{
			title: "Other Cover Crop",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
		{
			title: "Nitrogen",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
		{
			title: "Phosphorus",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
		{
			title: "Potash",
			health: "",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
	];
	const DefaultTotalResults = [
		{
			title: "Cost",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
		{
			title: "Revenue",
			benchmark: 0,
			current: 0,
			total_change: 0,
			change_per_acre: 0,
		},
	];
	const DefaultSumResults = [
		{ title: "Annual Change in Total Net Income ($/yr)", value: 0 },
		{ title: "Annual Change in Per Acre Net Income ($/ac/yr)", value: 0 },
		{ title: "Return on Investment (%)", value: 0 },
	];

	const [checked, setChecked] = useState(false);
	const [field, setField] = useState<Field>({
		id: 0,
		name: "",
		total_acres: 0,
		total_cc_acres: 0,
	});

	const [revenueResults, setRevenueResults] = useState<Array<RevenueResults>>(
		DefaultRevenueResults
	);
	const [costResults, setCostResults] =
		useState<Array<CostResults>>(DefaultCostResults);
	const [totalResults, setTotalResults] =
		useState<Array<TotalResults>>(DefaultTotalResults);
	const [sumResults, setSumResults] =
		useState<Array<SumResults>>(DefaultSumResults);

	React.useEffect(() => {
		let urlResults = "results";
		if (!checked) {
			urlResults = `results/${fieldId}`;
		}

		const fetchResults = async () => {
			await apiClient
				.get(urlResults)
				.then((result) => {
					if (result.status === 200) {
						setField({
							id: result.data.field_info.crop_id,
							name: result.data.field_info.crop_name,
							total_acres: result.data.field_info.total_acres,
							total_cc_acres: result.data.field_info.total_cc_acres,
						});
						setCostResults(result.data.budget_summary_results.cost_results);
						setRevenueResults(
							result.data.budget_summary_results.revenue_results
						);
						setTotalResults(result.data.budget_summary_results.total_results);
						setSumResults([
							{
								title: "Annual Change in Total Net Income ($/yr)",
								value: result.data.budget_summary_results.net_income,
							},
							{
								title: "Annual Change in Per Acre Net Income ($/ac/yr)",
								value: result.data.budget_summary_results.net_income_per_acre,
							},
							{
								title: "Return on Investment (%)",
								value: result.data.budget_summary_results.return_on_investment,
							},
						]);
					} else {
						console.error(`Failure fetching results. API Response: ${result}`);
					}
				})
				.catch((e) => {
					console.error(`Error fetching results from api: ${e}`);
				});
		};
		fetchResults();
	}, [fieldId, checked]);

	return (
		<div>
			<Grid container className={classes.budgetSummaryGrid}>
				<Grid item xs={6} style={{ padding: "5px 10px", textAlign: "left" }}>
					<b>Crop name:</b>{" "}
					{checked ? <span>all</span> : <span>{field.name}</span>}
					<br />
					<b>Acres:</b>{" "}
					{field.total_acres !== 0 ? field.total_acres.toFixed(2) : "NA"} <br />
					<b>Cover crop acres:</b>{" "}
					{field.total_cc_acres !== 0 ? field.total_cc_acres.toFixed(2) : "NA"}
				</Grid>
				<Grid item xs={6} style={{ padding: "5px 10px", textAlign: "right" }}>
					<FormControlLabel
						value={checked}
						control={
							<Checkbox onChange={() => setChecked(!checked)} color="primary" />
						}
						label="Calculate for all crops"
						labelPlacement="start"
					/>
				</Grid>
			</Grid>
			<Grid
				container
				justify="center"
				alignItems="center"
				className={classes.budgetSummaryGrid}
			>
				<Grid item xs={12} style={{ paddingBottom: "0px" }}>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell style={{ width: "20%" }} align="left">
										Changes in Cost
									</StyledTableCell>
									<StyledTableCell style={{ width: "20%" }} align="center">
										Associated Soil Health Practice(s)
									</StyledTableCell>
									<StyledTableCell align="right">
										Before&nbsp;($)
									</StyledTableCell>
									<StyledTableCell align="right">
										After&nbsp;($)
									</StyledTableCell>
									<StyledTableCell align="right">
										Total&nbsp;Change&nbsp;($)
									</StyledTableCell>
									<StyledTableCell align="right">
										Change&nbsp;($/acre)
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{costResults.map((row, i: number) => (
									<StyledTableRow key={i}>
										<StyledTableCell component="th" scope="row">
											{row.title}
										</StyledTableCell>
										<StyledTableCell align="center">
											<span style={{ color: "green" }}>{row.health}</span>
										</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.benchmark)}
										</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.current)}
										</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.total_change)}
										</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.change_per_acre)}
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell style={{ width: "20%" }} align="left">
										Changes in Revenue
									</StyledTableCell>
									<StyledTableCell style={{ width: "20%" }} align="center">
										Associated Soil Health Practice(s)
									</StyledTableCell>
									<StyledTableCell align="right">
										Before&nbsp;($)
									</StyledTableCell>
									<StyledTableCell align="right">
										After&nbsp;($)
									</StyledTableCell>
									<StyledTableCell align="right">
										Total&nbsp;Change&nbsp;($)
									</StyledTableCell>
									<StyledTableCell align="right">
										Change&nbsp;($/acre)
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{revenueResults.map((row, i: number) => (
									<StyledTableRow key={i}>
										<StyledTableCell component="th" scope="row">
											{row.title}
										</StyledTableCell>
										<StyledTableCell align="center">
											<span style={{ color: "green" }}>{row.health}</span>
										</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.benchmark)}
										</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.current)}
										</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.total_change)}
										</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.change_per_acre)}
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<TableContainer style={{ marginBottom: "1rem" }} component={Paper}>
						<Table className={classes.table} aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell style={{ width: "20%" }} align="left">
										Total
									</StyledTableCell>
									<StyledTableCell style={{ width: "20%" }} align="center">
										&nbsp;
									</StyledTableCell>
									<StyledTableCell align="right">
										Before&nbsp;($)
									</StyledTableCell>
									<StyledTableCell align="right">
										After&nbsp;($)
									</StyledTableCell>
									<StyledTableCell align="right">
										Total&nbsp;Change&nbsp;($)
									</StyledTableCell>
									<StyledTableCell align="right">
										Change&nbsp;($/acre)
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{totalResults.map((row, i: number) => (
									<StyledTableRow key={i}>
										<StyledTableCell component="th" scope="row">
											{row.title}
										</StyledTableCell>
										<StyledTableCell align="right">&nbsp;</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.benchmark)}
										</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.current)}
										</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.total_change)}
										</StyledTableCell>
										<StyledTableCell align="right">
											{toUsd(row.change_per_acre)}
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			<Grid container className={classes.budgetSummaryGrid}>
				<Grid item xs={12}>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="customized table">
							<TableBody>
								{sumResults.map((row, i: number) => (
									<StyledTableRow key={i}>
										<StyledTableCell align="left">{row.title}</StyledTableCell>
										<StyledTableCell align="right">
											{row.title.includes("%")
												? `${row.value?.toFixed(2)}%`
												: toUsd(row.value)}
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default PartialBudgetSummary;
