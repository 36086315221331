import React from "react";
import {makeStyles, Theme, createStyles} from "@material-ui/core/styles";
import {Breakpoint} from "@material-ui/core/styles/createBreakpoints";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PartialBudgetQuadrants from "../PartialBudgetQuadrants";
import CoverCropHistory from "../CoverCropHistory";
// import FieldSummary from "../FieldSummary";
import PartialBudgetSummary from "../PartialBudgetSummary";
import CropHistory from "../CropHistory";
import Guidance from "../Guidance";
/* eslint-disable */

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			// [theme.breakpoints.up(1620)]: {
			// 	"& div.MuiStepper-horizontal": {
			// 		position: "fixed",
			// 		width: "70%",
			// 		background: "white",
			// 		zIndex: 3,
			// 		// paddingBottom: 35,
			// 	},

			"& div.MuiStepper-horizontal": {
				position: "fixed",
				width: "70%",
				background: "white",
				zIndex: 3,
				// paddingBottom: 35,
			},

			"& span.MuiStepLabel-label.MuiStepLabel-active": {
				fontWeight: "bold",
			},

			"& span.MuiTypography-body2": {
				fontSize: "initial",
				fontWeight: "bold",
			},
		},
		root1: {
			width: "100%",
			borderBottom: "1px solid #d8d8d8",
			height: "7.5%",
			position: "fixed",
			zIndex: 2,
			background: "white",
			[theme.breakpoints.down(1135)]: {
				height: "8.5%",
			},
		},
		button: {
			marginRight: theme.spacing(1),
		},
		instructions: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			marginLeft: "2%",
		},
	})
);

function getSteps() {
	return [
		"Guidance",
		"Crop Info",
		"Cover Crop Info",
		"Partial Budget Analysis",
		"Budget Summary",
	];
}

function getStepContent(step: number) {
	switch (step) {
		default:
			return "";
	}
}

const HorizontalLinearStepper = (props: { selFieldId: null | number }) => {
	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set<number>());
	const steps = getSteps();

	const isStepOptional = (step: number) => {
		return step === 1;
	};

	const isStepSkipped = (step: number) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// const handleSkip = () => {
	// 	if (!isStepOptional(activeStep)) {
	// 		// You probably want to guard against something like this,
	// 		// it should never occur unless someone's actively trying to break something.
	// 		throw new Error("You can't skip a step that isn't optional.");
	// 	}

	// 	setActiveStep((prevActiveStep) => prevActiveStep + 1);
	// 	setSkipped((prevSkipped) => {
	// 		const newSkipped = new Set(prevSkipped.values());
	// 		newSkipped.add(activeStep);
	// 		return newSkipped;
	// 	});
	// };

	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<>
			{/* <div> */}
			<div className={classes.root}>
				<Stepper activeStep={activeStep}>
					{steps.map((label, index) => {
						const stepProps: { completed?: boolean } = {};
						const labelProps: { optional?: React.ReactNode } = {};

						if (isStepSkipped(index)) {
							stepProps.completed = false;
						}
						return (
							<Step
								key={label}
								{...stepProps}
								onClick={(e) => {
									if (activeStep === 1 || activeStep === 2) {
										//save these before moving
										setActiveStep(index);
										if (activeStep === 1) {
											let d = document.getElementById("cropsubmit");
											if (d && activeStep === 1) {
												d.click();
											}
										} else if (activeStep === 2) {
											let d = document.getElementById("covercropsubmit");
											if (d) {
												d.click();
											}
										}
									} else {
										setActiveStep(index);
									}
								}}
							>
								<StepLabel {...labelProps}>{label} </StepLabel>
							</Step>
						);
					})}
				</Stepper>
			</div>
			<div className={classes.root1}></div>

			<div className={classes.root}>
				<div style={{ paddingTop: 108 }}>
					{activeStep === 0 && <Guidance />}
					{activeStep === 1 ? (
						<CropHistory fieldId={props.selFieldId} />
					) : (
						<div />
					)}

					{activeStep === 2 ? (
						<CoverCropHistory fieldId={props.selFieldId} />
					) : (
						<div />
					)}
					{/* {value === "summary" ? <FieldSummary /> : <div />} */}
					{activeStep === 3 ? (
						<PartialBudgetQuadrants fieldId={props.selFieldId} />
					) : (
						<div />
					)}
					{activeStep === 4 && (
						<PartialBudgetSummary fieldId={props.selFieldId} />
					)}
				</div>
				<div style={{ textAlign: "left", paddingLeft: "1%" }}>
					<div style={{ paddingBottom: 18 }}>
						<Typography className={classes.instructions}>
							{getStepContent(activeStep)}
						</Typography>
						<div>
							<Button
								disabled={activeStep === 0}
								onClick={handleBack}
								className={classes.button}
							>
								Back
							</Button>

							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									if (activeStep === 1 || activeStep === 2) {
										//save these before moving
										handleNext();
										if (activeStep === 1) {
											let d = document.getElementById("cropsubmit");
											if (d && activeStep === 1) {
												d.click();
											}
										} else if (activeStep === 2) {
											let d = document.getElementById("covercropsubmit");
											if (d) {
												d.click();
											}
										}
									} else if (activeStep === 4) {
										handleReset();
									} else {
										handleNext();
									}
								}}
								className={classes.button}
							>
								{activeStep === steps.length - 1
									? "Finish"
									: activeStep === 1 || activeStep === 2
									? "Save and Move to Next Step"
									: "Next"}
							</Button>
						</div>
					</div>
				</div>
			</div>
			{/* </div> */}
		</>
	);
};
export default HorizontalLinearStepper;
