import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import Layout from "./common/Layout";
import "../styles/home.css";
import {Button, Divider} from "@material-ui/core";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import apiClient from "../utils/apiClient";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import states from "./common/States.json";
import config from "../app.config";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		justifyContent: "center",
	},
	form: {
		width: "100em",
		maxWidth: "100vw",
		padding: "1em",
	},
	input: {
		margin: theme.spacing(1),
	},
	horizontalView: {
		display: "block",
		justifyContent: "space-between",
		alignItems: "center",
		padding: theme.spacing(1),
		marginTop: 20,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	cancel: {
		marginRight: 55,
	},
	divider: {
		marginLeft: 8,
		marginRight: -8,
		border: "inset",
		marginBottom: 19,
		marginTop: 19,
	},
}));
/*eslint-disable*/
const Register = () => {
	const classes = useStyles(),
		dispatch = useDispatch(),
		history = useHistory(),
		[formState, setFormState] = useState({
			first_name: "",
			last_name: "",
			title: "",
			organization: "",
			state: "",
			hat: "",
			username: "",
			password: "",
			confirm_password: "",
		}),
		[errors, setErrors] = useState({
			first_name: "",
			last_name: "",
			username: "",
			password: "",
			confirm_password: "",
		}),
		[pending, setPending] = useState(false);

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setErrors({
			username: "",
			password: "",
			confirm_password: "",
			first_name: "",
			last_name: "",
		});
		setFormState({ ...formState, [name]: value });
	};

	// useEffect(() => {
	// 	console.log("HIT");
	// 	const fetchUserTypes = async () => {
	// 		await apiClient
	// 			.get("user-types")
	// 			.then((result) => {
	// 				if (result.status === 200) {
	// 					console.log(result.status);
	// 					// let results = result.data.map((row: TillageOption) => {
	// 					// 	return { id: row.id, name: row.name };
	// 					// });

	// 					// results.unshift(config.defaultTillageOption);
	// 					// setTillageOptions(results);
	// 				} else {
	// 					console.error(`Failure fetching tillages. API Response: ${result}`);
	// 				}
	// 			})
	// 			.catch((e) => {
	// 				console.error(`Error fetching tillage from api: ${e}`);
	// 			});
	// 	};
	// 	fetchUserTypes();
	// }, []);

	useEffect(() => {
		if (
			formState.password &&
			formState.confirm_password &&
			formState.password !== formState.confirm_password
		) {
			setErrors({
				first_name: "",
				last_name: "",
				username: "",
				password: "",
				confirm_password: "Passwords do not match.",
			});
		}
	}, [formState]);

	const register = async (e: any) => {
		e.preventDefault();
		setPending(true);
		const regResult = await apiClient.post("register", formState);
		setPending(false);
		if (regResult.status !== 200 || !regResult.data.success) {
			setErrors(
				regResult.data.errors || {
					username: "",
					password: "",
					confirm_password: "Unable to register.",
				}
			);
			return;
		}

		const form = new FormData();
		form.append("first_name", formState.first_name);
		form.append("last_name", formState.last_name);
		form.append("title", formState.title);
		form.append("organization", formState.organization);
		form.append("state", formState.state);
		form.append("hat", formState.hat);
		form.append("username", formState.username);
		form.append("password", formState.password);
		const result = await apiClient.post("login", form);
		if (result.data.user) {
			dispatch({ type: "AUTH_LOGIN", payload: result.data.user });
			history.push("/calculator");
		} else {
			setErrors({
				first_name: "",
				last_name: "",
				username: "",
				password: "",
				confirm_password: "Unknown error",
			});
		}
	};

	return (
		<Layout>
			<div className={classes.container}>
				<form onSubmit={register} className={classes.form}>
					<h2 className="secondary-color">Register</h2>
					<Grid container className={classes.container} spacing={2}>
						<Grid xs={5}>
							<TextField
								name="username"
								label="Email"
								type="email"
								required
								variant="outlined"
								fullWidth
								error={!!errors.username}
								className={classes.input}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={handleInputChange}
								value={formState.username}
								helperText={errors.username ? errors.username : undefined}
							/>
							<TextField
								name="password"
								type="password"
								required
								fullWidth
								error={!!errors.password}
								className={classes.input}
								label="Password"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								onChange={handleInputChange}
								value={formState.password}
								helperText={errors.password ? errors.password : undefined}
							/>
							<TextField
								name="confirm_password"
								type="password"
								required
								fullWidth
								error={!!errors.confirm_password}
								className={classes.input}
								label="Confirm Password"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								onChange={handleInputChange}
								value={formState.confirm_password}
								helperText={
									errors.confirm_password ? errors.confirm_password : undefined
								}
							/>
						</Grid>
						<HiddenGrid hidden={!config.showProfileFields}>
							<Divider className={classes.divider} />
							<TextField
								name="title"
								type="text"
								required
								fullWidth
								error={!!errors.password}
								className={classes.input}
								label="Title"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								onChange={handleInputChange}
								value={formState.title}
								// helperText={errors.title ? errors.title : undefined}
							/>
							<TextField
								name="first_name"
								label="First Name"
								type="text"
								required
								variant="outlined"
								fullWidth
								error={!!errors.first_name}
								className={classes.input}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={handleInputChange}
								value={formState.first_name}
								helperText={errors.first_name ? errors.first_name : undefined}
							/>
							<TextField
								name="last_name"
								type="text"
								required
								fullWidth
								error={!!errors.password}
								className={classes.input}
								label="Last Name"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								onChange={handleInputChange}
								value={formState.last_name}
								// helperText={errors.last_name ? errors.last_name : undefined}
							/>
							<FormControl
								variant="outlined"
								fullWidth
								className={classes.formControl}
							>
								<InputLabel htmlFor="outlined-age-native-simple">
									State
								</InputLabel>
								<Select
									native
									value={formState.state}
									onChange={handleInputChange}
									label="state"
									inputProps={{
										name: "state",
										id: "outlined-age-native-simple",
									}}
								>
									{states.map((s) => {
										return <option value={s.value}>{s.label}</option>;
									})}
								</Select>
							</FormControl>
							<TextField
								name="organization"
								type="text"
								required
								fullWidth
								// error={!!errors.organization}
								className={classes.input}
								label="Organization"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								onChange={handleInputChange}
								value={formState.organization}
								// helperText={
								// 	errors.organization ? errors.organization : undefined
								// }
							/>
							<TextField
								name="hat"
								type="text"
								required
								fullWidth
								error={!!errors.confirm_password}
								className={classes.input}
								label="What hat are you wearing?"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								onChange={handleInputChange}
								value={formState.hat}
								// helperText={errors.hat ? errors.hat : undefined}
							/>
						</HiddenGrid>
					</Grid>

					<div className={classes.horizontalView}>
						<Button
							color="secondary"
							component={Link}
							to="/"
							className={classes.cancel}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							disabled={pending}
							color="primary"
							type="submit"
						>
							Register
						</Button>
					</div>
				</form>
			</div>
		</Layout>
	);
};

export default Register;

interface HiddenGridProps {
	hidden: boolean;
	children: React.ReactNode;
}

function HiddenGrid({ hidden, ...props }: HiddenGridProps) {
	if (hidden) {
		return null;
	} else {
		return <Grid xs={5} {...props} />;
	}
}
