import React from "react";
import Typography from "@material-ui/core/Typography";

interface Info {
	node: any;
	children: any;
}

export default function CustomLink({node, children, ...props}: Info) {
	if (children) {
		return (
			<Typography paragraph variant="body1">
				{children}
			</Typography>
		); //eslint-disable-next-line
	} else {
		return null;
	}
}
