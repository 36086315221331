import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
/* eslint-disable */

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
	},
	indicator: {
		backgroundColor: "darkgreen",
	},
	para: {
		color: "#455A64",
		paddingBottom: "10px",
		paddingLeft: "20px",
		paddingRight: "20px",
		textAlign: "justify",
	},
	paraHeader: {
		fontSize: "1.0825rem",
		fontWeight: 600,
		paddingBottom: "6px",
	},
	ul: {
		marginBottom: "0px",
	},
	ulcircle: {
		listStyleType: "circle",
		marginBottom: "0px",
	},
	ulListitem: {
		paddingBottom: "8px",
	},
	subHeader: {
		fontWeight: 600,
		fontSize: "0.9rem",
		textTransform: "uppercase",
	},
	table: {
		minWidth: 650,
		maxWidth: 1050,
	},
	tableHeaderCell: {
		fontWeight: 600,
		fontSize: "0.90rem",
	},
	boldText: {
		fontWeight: 800,
	},
}));

const Guidance = () => {
	const classes = useStyles();

	return (
		<div>
			<h2 className="secondary-color">GUIDANCE</h2>

			<div className={classes.para}>
				<div className={classes.paraHeader}> PURPOSE </div>
			</div>
			<div className={classes.para}>
				<div className={classes.paraHeader}>
					{" "}
					1. Quantify the Economic Effects of Soil Health Practice{" "}
				</div>
				<div className={classes.para}>
					This Tool is designed to evaluate the effects of adopting soil health
					practices (rather than production or other changes unrelated to soil
					health practice adoption) for corn, soybean, and/or wheat production.
					Consequently, the information entered by the user should pertain to
					soil health practice adoption only. For example, an increase in
					herbicide cost for burn-down after adopting no-till should be
					included. In contrast, do not include herbicide changes unrelated to
					soil health practices such as input changes necessitated by
					glyphosate-resistant weeds or changes in herbicide costs due to
					"getting a better deal on prices at the co-op." The effects considered
					by the Tool include changes in yield, cash crop seeds cost, herbicide,
					insecticide, fungicide, tillage practices, nutrient management
					practices, cover crop seeds cost, cover crop establishment cost, and
					cover crop termination cost. For all effects, the Tool asks the user
					for an associated soil health practice: no-till or reduced-tillage,
					cover crops, and nutrient management. Users should select one of these
					three soil health practices from the soil health practice dropdown
					menu associated with each effect. If the user is uncertain which of
					the three soil health practices to associate with the economic effect,
					select "combined practices."
				</div>
			</div>

			<div className={classes.para}>
				<div className={classes.paraHeader}>
					{" "}
					2. Conduct a Retrospective or a Predictive Analysis of Soil Health
					Practice Economic Effects{" "}
				</div>
				<div className={classes.para}>
					This Tool can be used to analyze soil health practice costs and
					benefits retrospectively or predictively. The tool estimates the
					average annual change in net income and return on investment.
					<ul className={classes.ul}>
						<li className={classes.ulListitem}>
							<span className={classes.subHeader}>
								Retrospective Analysis:{" "}
							</span>
							Farmers who have already adopted soil health practices and have
							been using them successfully for four or more years will be
							conducting a retrospective analysis and should enter information
							based on their actual farm records and experience. The Tool will
							provide a quantitative estimate of how their bottom line has been
							impacted by the adoption of soil health practices on their farm.
						</li>
						<li className={classes.ulListitem}>
							<span className={classes.subHeader}>Predictive Analysis: </span>
							Farmers who are interested in trying soil health practices or have
							just begun experimenting with soil health practices can use the
							Tool to estimate how adoption of one or more practices could
							affect their bottom line. The Tool can be used for one or more
							analyses allowing the user to create a range of "what if"
							scenarios for their individual operation.
						</li>
					</ul>
				</div>
			</div>

			<div className={classes.para}>
				<div className={classes.paraHeader}> INSTRUCTIONS </div>
			</div>
			<div className={classes.para}>
				<div className={classes.paraHeader}> 1. Data Entry Tips </div>
				<div className={classes.para}>
					The SHEC Tool is designed to conduct a partial budget analysis of the
					economic effects of soil health practice adoption by comparing
					production data by crop that occurs "Before" and "After" soil health
					practice adoption. Starting with the "ADD CROP" button in the left
					margin, users enter data in each of the tabs as appropriate for their
					operation by crop. Tips are provided here in the Guidance tab and
					throughout the Tool via "Tool Tips" and "Pop-Up Boxes" with guidance
					for data entry.
					<br />
					<br />
					<div className={classes.paraHeader}>
						{" "}
						Data Entry Steps for a Successful Analysis:{" "}
					</div>
					<ul className={classes.ul}>
						<li className={classes.ulListitem}>
							<span className={classes.subHeader}>ADD CROP: </span>
							Enter Field Name and select crop from a dropdown list. Users must
							enter the same number of acres in this crop for both before and
							after acres because the Tool currently is unable to evaluate
							changes in the crop rotation. See instructions below on the proper
							method for entering acres by crop. Include the name of the crop in
							the field name for tracking purposes. Users may enter as many
							crops as needed using the "Add Crop" button. All crops added can
							be analyzed individually or together as a crop rotation in the
							economic analysis.
						</li>
						<li className={classes.ulListitem}>
							<span className={classes.subHeader}>CROP INFO: </span>
							Select a crop from "My Crops" in the left margin and complete all
							relevant data fields (yield, seed cost, herbicide, insecticide,
							fungicide, tillage, and nutrient management) using a combination
							of direct entry and selection from drop-down menus. Changes in the
							"Before" and "After" values are applied to the entire field
							acreage by cash crop. Both leaving the field blank or entering a
							zero indicate a zero value. However, for the nutrient management
							section, users must select "None" in the "Fertilizer" box for
							either "Before" or "After" if zero fertilizer is being applied.
							{/* Click the SAVE button at bottom of the page before moving to a
							different tab. */}
						</li>
						<li className={classes.ulListitem}>
							<span className={classes.subHeader}>COVER CROP INFO: </span>
							Select a crop from "My Crops" in the left margin and complete all
							relevant data fields for the cover crop preceding that cash crop.
							Acres planted in cover crop must be less than or equal to the cash
							crop acreage. If Cover Crops are new to the operation, enter zero
							in the "Before" fields or leave the "Before" fields blank. Click
							the SAVE button at bottom of the page before moving to a different
							tab.
						</li>
						<li className={classes.ulListitem}>
							<span className={classes.subHeader}>
								PARTIAL BUDGET ANALYSIS:{" "}
							</span>
							Review your results in a standard, four-quadrant Partial Budget
							Analysis format featuring increases in income, decreases in cost,
							decreases in income, and increases in cost for
							<ul className={classes.ulcircle}>
								<li>Each crop by selecting the crop in the left margin, or</li>
								<li>
									For all crops by clicking the "Calculate for all crops"
									checkbox in the upper right corner of the table.
								</li>
							</ul>
						</li>
						<li className={classes.ulListitem}>
							<span className={classes.subHeader}>BUDGET SUMMARY: </span> Review
							your results in a standard table format for
							<ul className={classes.ulcircle}>
								<li>Each crop by selecting the crop in the left margin, or</li>
								<li>
									For all crops by clicking the "Calculate for all crops"
									checkbox in the upper right corner of the table.
								</li>
							</ul>
						</li>
					</ul>
					<div className={classes.para}>
						<span className={classes.boldText}> Caution: </span> If users leave
						both Before and After input cells blank, the Tool will assume no
						changes occurred.
					</div>
				</div>
			</div>

			<div className={classes.para}>
				<div className={classes.paraHeader}>
					{" "}
					2. Proper Method for Entering Acres by Crop{" "}
				</div>
				<div className={classes.para}>
					For each crop grown, indicate the same acreage in "Before Acres" and
					"After Acres" when you "Add Crop". Currently the SHEC Tool is unable
					to evaluate changes in crop rotation. Keep in mind that this Tool
					estimates average annual net income. Thus, you must capture how crops
					rotate through one field over multiple years by splitting the field
					acreage to reflect the percent of the rotation that the crop occupies.
				</div>
				<div className={classes.para}>
					<span className={classes.boldText}> Example: </span> If you are
					analyzing a 400-acre field with a corn, wheat, soybean rotation where
					two years of corn is followed by 1 year of wheat then 1 year of
					soybeans, you will enter 200 acres for corn, 100 acres for wheat, and
					100 acres for soybeans to capture the rotation.
				</div>
				<div className={classes.para}>
					{/*<TableContainer component={Paper}>*/}
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell className={classes.tableHeaderCell}>
									Data Fields in Form
								</TableCell>
								<TableCell className={classes.tableHeaderCell} align="center">
									Crop 1
								</TableCell>
								<TableCell className={classes.tableHeaderCell} align="center">
									Crop 2
								</TableCell>
								<TableCell className={classes.tableHeaderCell} align="center">
									Crop 3
								</TableCell>
								<TableCell className={classes.tableHeaderCell} align="center">
									Total Field Acreage
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>Field Name</TableCell>
								<TableCell align="center">Lower 400 - Corn</TableCell>
								<TableCell align="center">Lower 400 - Wheat</TableCell>
								<TableCell align="center"> Lower 400 - Soybeans</TableCell>
								<TableCell align="center">Lower 400</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Crop (dropdown)</TableCell>
								<TableCell align="center">Corn</TableCell>
								<TableCell align="center">Wheat</TableCell>
								<TableCell align="center"> Soybeans</TableCell>
								<TableCell align="center">All</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Before Acreage</TableCell>
								<TableCell align="center">200</TableCell>
								<TableCell align="center">100</TableCell>
								<TableCell align="center">100</TableCell>
								<TableCell align="center">400</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>After Acreage</TableCell>
								<TableCell align="center">200</TableCell>
								<TableCell align="center">100</TableCell>
								<TableCell align="center">100</TableCell>
								<TableCell align="center">400</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					{/*</TableContainer>*/}
				</div>
			</div>

			<div className={classes.para}>
				<div className={classes.paraHeader}> DEFINITIONS </div>
				<ul className={classes.ulcircle}>
					<li className={classes.ulListitem}>
						<span className={classes.subHeader}>Partial Budget Analysis: </span>
						Is an analytical framework that compares the marginal costs and
						benefits of implementing an alternative, in this case, one or more
						soil health practices. The economic analysis includes only those
						changes that result from implementing the soil health practices
						being evaluated.
					</li>
					<li className={classes.ulListitem}>
						<span className={classes.subHeader}>Before: </span>
						The "Before" concept reflects typical costs and benefits of pre-soil
						health practice adoption. The "Before" data entry inputs should
						reflect observed or estimated costs and returns BEFORE the
						implementation of soil health practices. This could be reflective of
						a farmer’s historical, conventional practices from years past if
						conducting a retrospective analysis, or a farmer’s current,
						conventional practices if conducting a predictive analysis.
					</li>
					<li className={classes.ulListitem}>
						<span className={classes.subHeader}>After: </span>
						The "After" concept reflects typical costs and benefits post-soil
						health practice adoption. The "After" data entry inputs should
						reflect the observed or estimated costs and returns AFTER
						implementation of soil health practice adoption. This could be
						reflective of a farmer’s current soil health practice use if
						conducting a retrospective analysis, or a farmer’s future, potential
						use of soil health practices if conducting a predictive analysis.
					</li>
					<li className={classes.ulListitem}>
						<span className={classes.subHeader}>
							Annual Change in Total Net Income ($/yr.):{" "}
						</span>
						This is the average annual change in total net income for the
						acreage being evaluated by individual crop or for all crops (if
						users select the top right box for "Calculate for all crops" in
						either the Partial Budget Analysis tab or the Budget Summary tab).
					</li>
					<li className={classes.ulListitem}>
						<span className={classes.subHeader}>
							Annual Change in Net Income ($/acre/yr.):{" "}
						</span>
						This is the average annual per acre change in net income calculated
						by dividing the Annual Change in Total Net Income by the number of
						acres included in the analysis.
					</li>
					<li className={classes.ulListitem}>
						<span className={classes.subHeader}>
							Annual Change in Net Income ($/acre/yr.):{" "}
						</span>
						Return on Investment (commonly referred to as ROI) measures what you
						received compared with what you put in or, in other words, the ratio
						of Annual Change in Total Net Income to Annual Total Decreased Net
						Income (ROI = Net Profit/Total Costs).
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Guidance;
