import React, {useEffect, useState} from "react";
import "../../styles/home.css";
import {Divider, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ComponentTextfield from "../styledControls/ComponentTextfield";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ToolTip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Help from "@material-ui/icons/Help";

const useStyle = makeStyles(() => ({
	invisibleDivider: {
		margin: "8px 10px",
		backgroundColor: "transparent",
	},
	divider: {
		margin: "0px 10px 4px 10px",
	},
}));

interface InputProps {
	name: string;
	units?: { benchmark: string; current: string };
	selBenchmarkVal: null | number;
	selCurrentVal: null | number;
	selPracticeId: number;
	isCropInfo: boolean;
	parentCallBack: any;
	tooltip: null | string;
}

interface PracticeOption {
	id: number;
	name: string;
}

const ComponentInputBox = ({
	name,
	units,
	selBenchmarkVal,
	selCurrentVal,
	selPracticeId,
	isCropInfo,
	parentCallBack,
	tooltip,
}: InputProps) => {
	const classes = useStyle();
	const [benchmarkInputVal, setBenchmarkInputVal] = useState<null | number>(
		selBenchmarkVal
	);
	const [currInputVal, setCurrInputVal] = useState<null | number>(
		selCurrentVal
	);
	const [practiceId, setPracticeId] = useState<number>(selPracticeId);
	const [selectedOption, setSelectedOption] = useState<null | PracticeOption>(
		null
	);

	let options = [
		{id: 0, name: "-Select-"},
		{id: 1, name: "No Till or Reduced Till"},
		{id: 2, name: "Nutrient Management"},
		{id: 3, name: "Cover Crop"},
		{id: 4, name: "Combined Practices"},
	];
	// const isMounted = useRef(false);
	useEffect(() => {
		options.forEach((row: PracticeOption) => {
			if (row.id === selPracticeId) {
				setSelectedOption({id: row.id, name: row.name});
				setPracticeId(row.id);
			}
		});
	}, [selPracticeId]);

	useEffect(() => {
		setPracticeId(selPracticeId);
	}, [selPracticeId]);

	useEffect(() => {
		parentCallBack({
			current: currInputVal,
			benchmark: benchmarkInputVal,
			practiceId: practiceId,
		});
	}, [currInputVal, benchmarkInputVal, practiceId, parentCallBack]);

	let isDollar = false;

	if (units && units.benchmark === "$") {
		isDollar = true;
	}
	return (
		<div className="componentBox">
			<div className="boxText">
				<span>{name}</span>
				{tooltip ? (
					<span className="boxTooltip">
						<ToolTip
							title={<span style={{fontSize: "0.8rem"}}> {tooltip}</span>}
							enterTouchDelay={50}
						>
							<IconButton className="boxIconButton">
								<Help color="inherit" className="boxIcon" />
							</IconButton>
						</ToolTip>
					</span>
				) : (
					""
				)}
			</div>

			<ComponentTextfield
				name="Before"
				unit={isDollar ? "$" : ""}
				selectedValue={selBenchmarkVal}
				parentCallBack={setBenchmarkInputVal}
			/>
			{!isDollar ? (
				<span className="units"> {units ? units.benchmark : ""} </span>
			) : (
				""
			)}
			<Divider className={classes.divider} />
			<ComponentTextfield
				name="After"
				unit={isDollar ? "$" : ""}
				selectedValue={selCurrentVal}
				parentCallBack={setCurrInputVal}
			/>
			{!isDollar ? (
				<span className="units"> {units ? units.current : ""} </span>
			) : (
				""
			)}

			{isCropInfo ? (
				<div>
					{" "}
					<Divider className={classes.divider} />
					<Autocomplete
						style={{padding: 20}}
						options={options}
						value={selectedOption}
						getOptionSelected={(option: any, value: any) =>
							option.id === value.id
						}
						getOptionLabel={(option: any) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Soil Health Practice"
								variant="outlined"
								InputLabelProps={{shrink: true}}
							/>
						)}
						onChange={(event, value: null | PracticeOption) => {
							setSelectedOption(value);
							setPracticeId(value ? value.id : 0);
						}}
					/>
				</div>
			) : (
				<div> </div>
			)}
		</div>
	);
};

export default ComponentInputBox;
