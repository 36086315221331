import React from "react";
import "./App.css";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import HomePage from "./components/HomePage";
import LandingPage from "./components/LandingPage";
import FAQ from "./components/common/FAQ";
import References from "./components/common/References";
import About from "./components/common/About";
import Login from "./components/common/Login";
import Register from "./components/Register";
import CheckAuth from "./components/common/CheckAuth";

function App() {
	return (
		<div className="App">
			<CheckAuth />
			<Router>
				<Switch>
					<Route exact path="/calculator">
						<HomePage />
					</Route>
					<Route exact path="/">
						<LandingPage />
					</Route>
					<Route exact path="/faqs">
						<FAQ />
					</Route>
					<Route exact path="/documentation">
						<References />
					</Route>
					<Route exact path="/about">
						<About />
					</Route>
					<Route exact path="/login">
						<Login />
					</Route>
					<Route exact path="/register">
						<Register />
					</Route>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
