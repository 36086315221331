import React, {useEffect, useState} from "react";
import Layout from "./Layout";
import "../../styles/home.css";
import {Grid} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {apiClient} from "../../utils/axiosClients";
import {useDispatch, useSelector} from "react-redux";
import ReactMarkdown from "react-markdown";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {IconButton} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Link from "@material-ui/core/Link";
import CustomLink from "./CustomLink";
import CustomParagraph from "./CustomParagraph";

const StyledTableCell = withStyles((theme) => ({
	head: {
		padding: "10px 0px",
		lineHeight: "110%",
		// backgroundColor: "#F0F0F0",
		backgroundColor: "#FFFFFF",
		color: "#000000",
		fontWeight: 800,
	},
	body: {
		padding: "8px",
		fontSize: 14,
		"& input": {
			border: "solid 1px",
			borderColor: "#455A64",
			width: "75%",
			lineHeight: "120%",
		},
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	mainText: {
		margin: "0px",
		padding: "0px 0px",
		lineHeight: "160%",
	},
	paragraphText: {
		color: "#455A64",
		paddingBottom: "0px",
		paddingLeft: "50px",
		paddingRight: "50px",
		textAlign: "justify",
	},
	calculatorLinkText: {
		color: "#455A64",
		paddingBottom: "10px",
		paddingTop: "20px",
		textAlign: "center",
	},
	table: {
		padding: "0px 50px 15px 50px",
		margin: "0px",
	},
	minWidth2: {
		width: 400,
	},
	flex: {
		flex: 1,
	},
});

const sources = {
	data_sources: [
		{
			title: "Machinery Cost Estimates",
			geographic_relevance: "Mid-Western",
			link_text:
				"Field Operations, Farm Business Mgt., University of Illinois, August 2019",
			url: "http://farmdoc.illinois.edu/manage/machinery/field_operations_2017.pdf",
		},
		{
			title: "Machinery Costs for Fertilizer Application",
			geographic_relevance: "Mid-Western",
			link_text:
				"Mid-Western Iowa Farm Custom Rate Survey, Iowa State University Extension and Outreach, 2019, Revised March 2019",
			url: "https://www.extension.iastate.edu/agdm/crops/pdf/a3-10.pdf",
		},
		{
			title: "NASS Index of Prices Paid",
			geographic_relevance: "National",
			link_text:
				"Producer Prices Paid Index, National Agricultural Statistics Service",
			url: "https://www.nass.usda.gov/Charts_and_Maps/graphics/data/all_prod.txt",
		},
		{
			title: "Crop Prices - Non-organic",
			geographic_relevance: "National",
			link_text:
				"Crop Values 2019 Summary, February 2020, USDA, National Agricultural Statistics Service",
			url: "https://www.nass.usda.gov/Publications/Todays_Reports/reports/cpvl0220.pdf",
		},
		{
			title: "Crop Prices - Organic, Corn, Soybeans, Hay, Wheat",
			geographic_relevance: "National",
			link_text:
				"National Organic Grain and Feedstuffs Report, National Agricultural Statistics Service, January 29, 2020",
			url: "https://www.ams.usda.gov/mnreports/lsbnof.pdf",
		},
		{
			title: "Fertilizers - The prices of five of the eight major fertilizers",
			geographic_relevance: "National",
			link_text: "DTN Retail Fertilizer Trends",
			url: "https://www.dtnpf.com/agriculture/web/ag/crops/article/2020/11/18/dap-prices-close-2019-levels-see",
		},
	],
};

interface Faq {
	id: number;
	question: string;
	answer: string;
	category_label: string;
	order: number;
	category_id: number;
}

const FAQ = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const store: any = useSelector((store) => store);

	const [FAQs, setFAQ] = useState<[] | [Faq]>([]);
	const [loaded, setDummyLoaded] = useState(false);
	const [open, setOpen] = useState(false);
	const [answer, setAnswer] = useState("");
	const [header, setHeader] = useState("");

	//override link and paragraph for markdown. Used to keep links from going back to server and keeping paragraphs typography consistent
	const renderers: any = {link: CustomLink, paragraph: CustomParagraph};

	useEffect(() => {
		const fetchField = async () => {
			dispatch({type: "SET_LOADING", loading: true});
			await apiClient
				.get("faqs")
				.then((result) => {
					if (result.status === 200) {
						let results = result.data.map((row: Faq) => {
							return {
								id: row.id,
								question: row.question,
								answer: row.answer,
								category_label: row.category_label,
								order: row.order,
								category_id: row.category_id,
							};
						});

						setFAQ(results);
						setDummyLoaded(true);
						/* eslint-disable-next-line */
					} else {
						console.error(`Failure fetching field. API Response: ${result}`);
					}
					dispatch({type: "SET_LOADING", loading: false});
				})
				.catch((e) => {
					dispatch({type: "SET_LOADING", loading: false});
					console.error(`Error fetching field from api: ${e}`);
				});
		};
		fetchField();
	}, []);

	let prevVal: number = 0;
	let hit: boolean = false;
	let question: string = "";
	return (
		<Layout>
			<Grid container item xs={12}>
				<Grid className={classes.mainText} item xs={12}>
					<div>
						<h2 className="secondary-color">
							Frequently Asked Questions (FAQ)
						</h2>
						<br />
						<div className={classes.paragraphText}>
							<>
								{FAQs &&
									FAQs.map((f: Faq, index: number) => {
										hit = false;
										if (prevVal === 0) {
											//first go round
											prevVal = f.category_id;
										}
										if (prevVal !== f.category_id) {
											//second & up
											prevVal = f.category_id;
											hit = true;
										}

										question = `${f.order}.) ${f.question}`;
										return (
											<>
												{(index === 0 || hit) && (
													<p
														style={{
															marginLeft: "5%",
															marginTop: index !== 0 ? "1.5%" : "0",
														}}
													>
														<b>{f.category_label}</b>
													</p>
												)}
												<p
													style={{marginLeft: "6%", color: "blue"}}
													onClick={() => {
														return (
															setAnswer(f.answer),
															setHeader(f.question),
															setOpen(true)
														);
													}}
												>
													{prevVal === f.category_id && question}
												</p>
											</>
										);
									})}
							</>
						</div>
						<br />
						<div className={classes.paragraphText}>
							<h4 className="secondary-color" style={{marginLeft: "5%"}}>
								Contact for more information: &nbsp;
								<p style={{fontWeight: 200, display: "inline"}}>
									Ellen Yeatman, Water Scientist & Ag Economist, &nbsp;
									<Link href="eyeatman@farmland.org">
										eyeatman@farmland.org
									</Link>
								</p>
							</h4>
						</div>
					</div>
					<br />
				</Grid>
				<hr
					style={{
						width: "94%",
						height: "1px",
						backgroundColor: "#455a64",
					}}
				/>
				<Grid item xs={12}>
					{/* <div className={classes.paragraphText}> */}
					<br />
					<div>
						<h2 className="secondary-color">Answers</h2>
						<br />
						<div className={classes.paragraphText}>
							<>
								{FAQs &&
									FAQs.map((f: Faq, index: number) => {
										hit = false;
										if (prevVal === 0) {
											//first go round
											prevVal = f.category_id;
										}
										if (prevVal !== f.category_id) {
											//second & up
											prevVal = f.category_id;
											hit = true;
										}

										question = `${f.order}.) ${f.question}`;

										return (
											<>
												{(index === 0 || hit) && (
													<p
														style={{
															marginTop: index !== 0 ? "1.5%" : "0",
															marginLeft: "5%",
														}}
													>
														<b>{f.category_label}</b>
													</p>
												)}
												<p style={{marginLeft: "6%"}}>
													{prevVal === f.category_id && question}
												</p>
												<p style={{marginLeft: "7%"}}>
													{prevVal === f.category_id && (
														<ReactMarkdown renderers={renderers}>
															{f.answer}
														</ReactMarkdown>
													)}
												</p>
											</>
										);
									})}
							</>
						</div>
						<br />
					</div>

					<div style={{marginTop: "2.5%"}}>
						<h2 className="secondary-color" style={{fontSize: "larger"}}>
							For more information, contact:
						</h2>
						<p style={{fontWeight: "normal", fontSize: "16px"}}>
							Ellen Yeatman, Water Scientist & Ag Economist, &nbsp;
							<Link href="eyeatman@farmland.org">eyeatman@farmland.org</Link>
						</p>
					</div>
				</Grid>
			</Grid>

			<Dialog open={open} classes={{paper: classes.minWidth2}}>
				<Toolbar style={{backgroundColor: "lightgray"}}>
					<Typography variant="h6" className={classes.flex}>
						{header}
					</Typography>
					<IconButton aria-label="Close Dialog" onClick={() => setOpen(false)}>
						<Close />
					</IconButton>
				</Toolbar>
				<DialogContent>
					<DialogContentText>
						<ReactMarkdown renderers={renderers}>{answer}</ReactMarkdown>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</Layout>
	);
};

export default FAQ;
