import React, {useEffect, useState} from "react";
import ComponentInputBox from "./common/ComponentInputBox";
import {makeStyles} from "@material-ui/core/styles";
import {Button, TextField, Tooltip} from "@material-ui/core";
import {apiClient} from "../utils/axiosClients";
import {useDispatch, useSelector} from "react-redux";
import {
	ccAcresTooltip,
	ccEstTooltip,
	ccSeedsTooltip,
	ccTermTooltip,
} from "../app.messages";

const useStyles = makeStyles(() => ({
	sameRowComponents: {
		display: "flex",
	},
	invisibleDivider: {
		margin: "10px 10px",
		backgroundColor: "transparent",
	},
	divider: {
		margin: "0px 10px 4px 10px",
	},
	buttonContainer: {
		textAlign: "left",
		padding: "30px",
		display: "none",
	},
	button: {
		backgroundColor: "darkgreen",
		fontSize: "16px",
	},
	acresInput: {
		margin: "10px 5px 10px 24px",
		textAlign: "right",
		lineHeight: "75px",
		"& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
			{
				display: "none",
			},
	},
}));

interface cropProps {
	fieldId: null | number; // TODO: remove null and make sure crophistory is only rendered when fieldId is available
}

const CoverCropHistory = ({fieldId}: cropProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const store: any = useSelector((store) => store);
	let cropAcres = store.cropField.cropAcres;

	const [seedCostInputs, setSeedCostInputs] = useState<{
		benchmark: null | number;
		current: null | number;
	}>({benchmark: null, current: null});
	const [estCostInputs, setEstCostInputs] = useState<{
		benchmark: null | number;
		current: null | number;
	}>({benchmark: null, current: null});
	const [termCostInputs, setTermCostInputs] = useState<{
		benchmark: null | number;
		current: null | number;
	}>({benchmark: null, current: null});
	const [ccAcres, setCcAcres] = useState<null | number>(null);

	const [message, setMessage] = useState("");
	const [apiResponse, setApiResponse] = useState("na");
	/* eslint-disable */
	useEffect(() => {
		if (fieldId) {
			const fetchField = async () => {
				dispatch({ type: "SET_LOADING", loading: true });
				await apiClient
					.get(`fields/${fieldId}`)
					.then((result) => {
						if (result.status === 200) {
							let cropJson = result.data;

							if (
								cropJson.cover_crop_info &&
								cropJson.cover_crop_info.benchmark
							) {
								let benchmark = cropJson.cover_crop_info.benchmark;
								let current = cropJson.cover_crop_info.current;

								setSeedCostInputs({
									benchmark: benchmark.seeds_cost,
									current: current.seeds_cost,
								});
								setEstCostInputs({
									benchmark: benchmark.establishment_cost,
									current: current.establishment_cost,
								});
								setTermCostInputs({
									benchmark: benchmark.termination_cost,
									current: current.termination_cost,
								});
								setCcAcres(cropJson.cover_crop_info.acres);
							} else {
								setSeedCostInputs({ benchmark: null, current: null });
								setEstCostInputs({ benchmark: null, current: null });
								setTermCostInputs({ benchmark: null, current: null });
								setCcAcres(null);
							}
						} else {
							console.error(`Failure fetching field. API Response: ${result}`);
						}
						dispatch({ type: "SET_LOADING", loading: false });
					})
					.catch((e) => {
						dispatch({ type: "SET_LOADING", loading: false });
						console.error(`Error fetching field from api: ${e}`);
					});
			};
			fetchField();
			setApiResponse("na");
		}
	}, [fieldId]);

	useEffect(() => {
		setApiResponse("na");
		if (ccAcres != null) {
			if (ccAcres === 0) {
				setMessage(
					"Cover crop acres must be greater than 0 or empty if not applicable"
				);
			} else if (ccAcres > cropAcres) {
				setMessage("Cover crop acres cannot exceed crop acres");
			} else {
				setMessage("");
			}
		} else {
			setMessage("");
		}
	}, [ccAcres, cropAcres]);

	const handleSubmit = () => {
		dispatch({ type: "SET_LOADING", loading: true });
		let ccJson = {
			cover_crop_info: {
				acres: ccAcres,
				benchmark: {
					seeds_cost: seedCostInputs.benchmark,
					establishment_cost: estCostInputs.benchmark,
					termination_cost: termCostInputs.benchmark,
				},
				current: {
					seeds_cost: seedCostInputs.current,
					establishment_cost: estCostInputs.current,
					termination_cost: termCostInputs.current,
				},
			},
		};

		apiClient
			.put(`fields/${fieldId}`, ccJson, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((result) => {
				if (result.status !== 200) {
					setApiResponse("failure");
					console.error(
						`Failure updating the new field. API Response: ${result}`
					);
					setMessage(
						"Something went wrong. Failed to save the crop information"
					);
				} else {
					setApiResponse("success");
					setMessage("Changes have been saved");
				}
				dispatch({ type: "SET_LOADING", loading: false });
			})
			.catch((e) => {
				dispatch({ type: "SET_LOADING", loading: false });
				console.error(`Error updating the new field from api: ${e}`);
			});
	};

	return (
		<div>
			<br />
			<div className={classes.sameRowComponents}>
				<Tooltip
					title={<span style={{ fontSize: "0.8rem" }}> {ccAcresTooltip}</span>}
					placement="right"
				>
					<TextField
						className={classes.acresInput}
						label="Acres Planted"
						variant="outlined"
						type="number"
						required={true}
						InputLabelProps={{ shrink: true }}
						value={ccAcres !== null && ccAcres >= 0 ? ccAcres : ""}
						onChange={(e) =>
							setCcAcres(e.target.value ? parseFloat(e.target.value) : null)
						}
					/>
				</Tooltip>
			</div>
			{ccAcres !== null && ccAcres >= 0 && (
				<>
					<div className="sectionBanner" style={{ marginBottom: "25px" }}>
						Indicate changes in cover crop costs associated ONLY with soil
						health practice adoption. Both leaving the field blank or entering a
						zero indicate a zero value.
						{/* Click the SAVE button at bottom of the
						page before moving to a different tab. */}
					</div>

					<ComponentInputBox
						name="Cover Crop Seeds Cost/Acre ($)"
						units={{ benchmark: "$", current: "$" }}
						parentCallBack={setSeedCostInputs}
						selBenchmarkVal={seedCostInputs.benchmark}
						selCurrentVal={seedCostInputs.current}
						selPracticeId={0}
						isCropInfo={false}
						tooltip={ccSeedsTooltip}
					/>

					<div className={classes.sameRowComponents}>
						<ComponentInputBox
							name="Cover Crop Establishment Cost/Acre ($)"
							units={{ benchmark: "$", current: "$" }}
							parentCallBack={setEstCostInputs}
							selBenchmarkVal={estCostInputs.benchmark}
							selCurrentVal={estCostInputs.current}
							selPracticeId={0}
							isCropInfo={false}
							tooltip={ccEstTooltip}
						/>

						<ComponentInputBox
							name="Cover Crop Termination Cost/Acre ($)"
							units={{ benchmark: "$", current: "$" }}
							parentCallBack={setTermCostInputs}
							selBenchmarkVal={termCostInputs.benchmark}
							selCurrentVal={termCostInputs.current}
							selPracticeId={0}
							isCropInfo={false}
							tooltip={ccTermTooltip}
						/>
					</div>

					<div
						style={{
							textAlign: "left",
							fontWeight: "bolder",
							fontSize: "18px",
							marginLeft: "30px",
						}}
					>
						{apiResponse === "success" ? (
							<span style={{ color: "green" }}> {message}</span>
						) : (
							<span style={{ color: "orange" }}> {message} </span>
						)}
					</div>
				</>
			)}
			<div className={classes.buttonContainer}>
				<Button
					id="covercropsubmit"
					variant="contained"
					color="primary"
					size="large"
					onClick={handleSubmit}
					className={classes.button}
					disabled={
						(ccAcres !== null && ccAcres <= 0) ||
						(ccAcres !== null && ccAcres > cropAcres)
					}
				>
					Save
				</Button>
				{/*&nbsp;&nbsp;&nbsp;*/}
				{/*<Button variant="contained" color="primary" size="large" className={classes.button}> Reset </Button>*/}
			</div>
		</div>
	);
};

export default CoverCropHistory;
