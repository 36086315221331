import React, {useState, useEffect} from "react";
import "../styles/home.css";
import ComponentInputBox from "./common/ComponentInputBox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {makeStyles} from "@material-ui/core/styles";
import Tillage from "./controls/Tillage";
import {Divider, Button} from "@material-ui/core";
import {apiClient} from "../utils/axiosClients";
import Fertilizer from "./controls/Fertilizer";
import {useDispatch, useSelector} from "react-redux";
import {
	fertilizerTooltip,
	fungicideTooltip,
	herbicideTooltip,
	insecticideTooltip,
	seedsTooltip,
	tillageTooltip,
	yieldTooltip,
} from "../app.messages";
import ToolTip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Help from "@material-ui/icons/Help";
import config from "../app.config";
/* eslint-disable */

const useStyles = makeStyles(() => ({
	sameRowComponents: {
		display: "flex",
		flexFlow: "wrap",
	},
	invisibleDivider: {
		margin: "10px 10px",
		backgroundColor: "transparent",
	},
	divider: {
		margin: "0px 10px 4px 10px",
	},
	buttonContainer: {
		textAlign: "left",
		padding: "30px",
		display: "none",
	},
	checkboxContainer: {
		textAlign: "left",
		paddingLeft: "30px",
	},
	button: {
		backgroundColor: "darkgreen",
		fontSize: "16px",
	},
}));

interface TillageOption {
	id: number;
	name: string;
}

interface TillageType {
	id: number;
	passes: number | null;
}

interface Fertilizers {
	applicatorId: number;
	products: Array<{ id: number; amount: number; price: number }>;
}

interface FertilizerOption {
	id: number;
	name: string;
}

interface FertilizerProductOption {
	id: number;
	name: string;
	unit: string;
	price: number;
}

interface cropProps {
	fieldId: null | number; // TODO: remove null and make sure crophistory is only rendered when fieldId is available
	// activeStep: number;
}

interface costInputs {
	benchmark: null | number;
	current: null | number;
	practiceId: number;
}

const CropHistory = ({ fieldId }: cropProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const store: any = useSelector((store) => store);
	let unit = store.cropField.cropUnit ? store.cropField.cropUnit : "bushel";

	const defaultTillages = [
		{ id: 0, passes: null },
		{ id: 0, passes: null },
		{ id: 0, passes: null },
	];

	const defaultFertilizers = [
		{ applicatorId: 0, products: [] },
		{ applicatorId: 0, products: [] },
		{ applicatorId: 0, products: [] },
	];

	const defaultCost = { benchmark: null, current: null, practiceId: 0 };

	//TODO: Simplify null types in typedefs?
	const [tillageOptions, setTillageOptions] = useState<[] | [TillageOption]>(
		[]
	);
	const [fertilizerApplOptions, setfertilizerApplOptions] = useState<
		Array<FertilizerOption>
	>([]);
	const [fertilizerProductOptions, setfertilizerProductOptions] = useState<
		Array<FertilizerProductOption>
	>([]);
	const [benchmarkTillages, setBenchmarkTillages] =
		useState<Array<TillageType>>(defaultTillages);
	const [currTillages, setCurrTillages] =
		useState<Array<TillageType>>(defaultTillages);

	const [yieldInputs, setYieldInputs] = useState<costInputs>(defaultCost);
	const [seedCostInputs, setSeedCostInputs] = useState<costInputs>(defaultCost);
	const [herbicideCostInputs, setHerbicideCostInputs] =
		useState<costInputs>(defaultCost);
	const [fungicideCostInputs, setFungicideCostInputs] =
		useState<costInputs>(defaultCost);
	const [insecticideCostInputs, setInsecticideCostInputs] =
		useState<costInputs>(defaultCost);

	const [overridePrices, setOverridePrices] = useState(false);
	const [benchmarkFertilizers, setBenchmarkFertilizers] =
		useState<Array<Fertilizers>>(defaultFertilizers);
	const [currFertilizers, setCurrFertilizers] =
		useState<Array<Fertilizers>>(defaultFertilizers);

	const [loaded, setDummyLoaded] = useState(false);
	const [userMessage, setUserMessage] = useState("");
	const [apiResponse, setApiResponse] = useState("na");
	const emptyFieldWarning =
		"We notice that you left values blank in section one. Blanks will be treated as zero.";
	const [step, setStep] = useState(1);

	// useEffect(() => {
	// 	if (activeStep === 1) {
	// 		console.log("EFFECT");
	// 	}
	// }, [activeStep, step]);

	useEffect(() => {
		setApiResponse("na");
		let validationFailedFor = [];
		if (
			(yieldInputs.current === null && yieldInputs.benchmark !== null) ||
			(yieldInputs.current !== null && yieldInputs.benchmark === null)
		) {
			validationFailedFor.push("Yield/Acre");
		}

		if (
			(seedCostInputs.current === null && seedCostInputs.benchmark !== null) ||
			(seedCostInputs.current !== null && seedCostInputs.benchmark === null)
		) {
			validationFailedFor.push("Seeds Cost/Acre");
		}

		if (
			(herbicideCostInputs.current === null &&
				herbicideCostInputs.benchmark !== null) ||
			(herbicideCostInputs.current !== null &&
				herbicideCostInputs.benchmark === null)
		) {
			validationFailedFor.push("Herbicide Cost/Acre");
		}

		if (
			(fungicideCostInputs.current === null &&
				fungicideCostInputs.benchmark !== null) ||
			(fungicideCostInputs.current !== null &&
				fungicideCostInputs.benchmark === null)
		) {
			validationFailedFor.push("Fungicide Cost/Acre");
		}

		if (
			(insecticideCostInputs.current === null &&
				insecticideCostInputs.benchmark !== null) ||
			(insecticideCostInputs.current !== null &&
				insecticideCostInputs.benchmark === null)
		) {
			validationFailedFor.push("Insecticide Cost/Acre");
		}

		if (validationFailedFor.length > 0) {
			setUserMessage(
				`${emptyFieldWarning} Please check the values for: ${validationFailedFor.join(
					", "
				)} . If this is ok, please click "Save" to continue.`
			);
		} else {
			setUserMessage("");
		}
	}, [
		yieldInputs,
		seedCostInputs,
		herbicideCostInputs,
		fungicideCostInputs,
		insecticideCostInputs,
	]);

	useEffect(() => {
		if (fieldId) {
			const fetchField = async () => {
				dispatch({ type: "SET_LOADING", loading: true });
				await apiClient
					.get(`fields/${fieldId}`)
					.then((result) => {
						if (result.status === 200) {
							let cropJson = result.data;

							// All fields of fieldinfo should always be set, so checking one is enough
							if (cropJson.crop_info && cropJson.crop_info.benchmark) {
								let benchmark = cropJson.crop_info.benchmark;
								let current = cropJson.crop_info.current;
								var seeds = cropJson.crop_info.practices.seeds_cost
									? cropJson.crop_info.practices.seeds_cost
									: 0;
								var yields = cropJson.crop_info.practices.yield
									? cropJson.crop_info.practices.yield
									: 0;
								var herbs = cropJson.crop_info.practices.herbicide_cost
									? cropJson.crop_info.practices.herbicide_cost
									: 0;

								var insect = cropJson.crop_info.practices.insecticide_cost
									? cropJson.crop_info.practices.insecticide_cost
									: 0;
								var fung = cropJson.crop_info.practices.fungicide_cost
									? cropJson.crop_info.practices.fungicide_cost
									: 0;
								setYieldInputs({
									benchmark: benchmark.yield,
									current: current.yield,
									practiceId: yields,
								});
								setSeedCostInputs({
									benchmark: benchmark.seeds_cost,
									current: current.seeds_cost,
									practiceId: seeds,
								});
								setHerbicideCostInputs({
									benchmark: benchmark.herbicide_cost,
									current: current.herbicide_cost,
									practiceId: herbs,
								});
								setInsecticideCostInputs({
									benchmark: benchmark.insecticide_cost,
									current: current.insecticide_cost,
									practiceId: insect,
								});
								setFungicideCostInputs({
									benchmark: benchmark.fungicide_cost,
									current: current.fungicide_cost,
									practiceId: fung,
								});
							} else {
								setYieldInputs(defaultCost);
								setSeedCostInputs(defaultCost);
								setHerbicideCostInputs(defaultCost);
								setInsecticideCostInputs(defaultCost);
								setFungicideCostInputs(defaultCost);
							}

							if (cropJson.crop_tillages && cropJson.crop_tillages.benchmark) {
								setBenchmarkTillages(cropJson.crop_tillages.benchmark);
								setCurrTillages(cropJson.crop_tillages.current);
							} else {
								setBenchmarkTillages(defaultTillages);
								setCurrTillages(defaultTillages);
							}

							if (
								cropJson.crop_fertilizers &&
								cropJson.crop_fertilizers.benchmark
							) {
								setOverridePrices(
									cropJson.crop_fertilizers.override_prices || false
								);
								setBenchmarkFertilizers(cropJson.crop_fertilizers.benchmark);
								setCurrFertilizers(cropJson.crop_fertilizers.current);
							} else {
								setBenchmarkFertilizers(defaultFertilizers);
								setCurrFertilizers(defaultFertilizers);
							}
							setDummyLoaded(true);
						} else {
							console.error(`Failure fetching field. API Response: ${result}`);
						}
						dispatch({ type: "SET_LOADING", loading: false });
					})
					.catch((e) => {
						console.error(`Error fetching field from api: ${e}`);
						dispatch({ type: "SET_LOADING", loading: false });
					});
			};
			fetchField();
			setApiResponse("na");
		}
	}, [fieldId]);

	useEffect(() => {
		const fetchTillages = async () => {
			await apiClient
				.get("tillage-machineries")
				.then((result) => {
					if (result.status === 200) {
						let results = result.data.map((row: TillageOption) => {
							return { id: row.id, name: row.name };
						});

						results.unshift(config.defaultTillageOption);
						setTillageOptions(results);
					} else {
						console.error(`Failure fetching tillages. API Response: ${result}`);
					}
				})
				.catch((e) => {
					console.error(`Error fetching tillage from api: ${e}`);
				});
		};

		const fetchFertilizerApplications = async () => {
			await apiClient
				.get("fertilizer-applications")
				.then((result) => {
					if (result.status === 200) {
						let results = result.data.map((row: FertilizerOption) => {
							return { id: row.id, name: row.name };
						});
						results.unshift(config.defaultFertilizerOption);
						setfertilizerApplOptions(results);
					} else {
						console.error(
							`Failure fetching fertilizer applicators. API Response: ${result}`
						);
					}
				})
				.catch((e) => {
					console.error(`Error fetching fertilizer applicators from api: ${e}`);
				});
		};

		const fetchFertilizerProducts = async () => {
			await apiClient
				.get("fertilizer-products")
				.then((result) => {
					if (result.status === 200) {
						let results = result.data.map((row: FertilizerProductOption) => {
							return {
								id: row.id,
								name: row.name,
								unit: row.unit,
								price: row.price,
							};
						});
						setfertilizerProductOptions(results);
					} else {
						console.error(
							`Failure fetching fertilizer products. API Response: ${result}`
						);
					}
				})
				.catch((e) => {
					console.error(`Error fetching fertilizer products from api: ${e}`);
				});
		};
		fetchTillages();
		fetchFertilizerApplications();
		fetchFertilizerProducts();
	}, []);

	const handleSubmit = () => {
		dispatch({ type: "SET_LOADING", loading: true });

		//TODO: typecast this json?
		let cropJson = {
			crop_info: {
				practices: {
					yield: yieldInputs.practiceId,
					seeds_cost: seedCostInputs.practiceId,
					herbicide_cost: herbicideCostInputs.practiceId,
					insecticide_cost: insecticideCostInputs.practiceId,
					fungicide_cost: fungicideCostInputs.practiceId,
				},
				benchmark: {
					yield: yieldInputs.benchmark,
					seeds_cost: seedCostInputs.benchmark,
					herbicide_cost: herbicideCostInputs.benchmark,
					insecticide_cost: insecticideCostInputs.benchmark,
					fungicide_cost: fungicideCostInputs.benchmark,
				},
				current: {
					yield: yieldInputs.current,
					seeds_cost: seedCostInputs.current,
					herbicide_cost: herbicideCostInputs.current,
					insecticide_cost: insecticideCostInputs.current,
					fungicide_cost: fungicideCostInputs.current,
				},
			},
			crop_tillages: {
				benchmark: benchmarkTillages,
				current: currTillages,
			},
			crop_fertilizers: {
				override_prices: overridePrices,
				benchmark: benchmarkFertilizers,
				current: currFertilizers,
			},
		};

		apiClient
			.put(`fields/${fieldId}`, cropJson, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((result) => {
				if (result.status !== 200) {
					setApiResponse("failure");
					setUserMessage("There was an error saving the crop information");
					console.error(
						`Failure updating the new field. API Response: ${result}`
					);
				} else {
					setApiResponse("success");
					setUserMessage("Changes have been saved.");
				}
				dispatch({ type: "SET_LOADING", loading: false });
			})
			.catch((e) => {
				dispatch({ type: "SET_LOADING", loading: false });
				setUserMessage("There was an error saving the crop information");
				console.error(`Error updating the new field from api: ${e}`);
			});
	};

	const handleBenchmarkTillage = (idx: number, tillageVal: TillageType) => {
		let updTillages = [...benchmarkTillages];
		updTillages[idx] = tillageVal;
		setBenchmarkTillages(updTillages);
	};

	const handleCurrTillage = (idx: number, tillageVal: TillageType) => {
		let updTillages = [...currTillages];
		updTillages[idx] = tillageVal;
		setCurrTillages(updTillages);
	};

	const handleBenchmarkFertilizer = (idx: number, fertVal: Fertilizers) => {
		setBenchmarkFertilizers((benchmarkFertilizers) => {
			let updFertilizers = [...benchmarkFertilizers];
			updFertilizers[idx] = fertVal;
			return updFertilizers;
		});
	};

	const handleCurrFertilizer = (idx: number, fertVal: Fertilizers) => {
		setCurrFertilizers((currFertilizers) => {
			let updFertilizers = [...currFertilizers];
			updFertilizers[idx] = fertVal;
			return updFertilizers;
		});
	};

	return (
		<>
			{loaded && (
				<div>
					<div className="sectionBanner">
						Indicate changes in yield, seed, and chemical costs associated ONLY
						with soil health practices. Both leaving the field blank or entering
						a zero indicate a zero value.
						{/* Click the SAVE button at bottom of the page
				before moving to a different tab. */}
					</div>
					<div className={classes.sameRowComponents}>
						<ComponentInputBox
							name="Yield/Acre"
							units={{ benchmark: unit, current: unit }}
							parentCallBack={setYieldInputs}
							selBenchmarkVal={yieldInputs.benchmark}
							selCurrentVal={yieldInputs.current}
							selPracticeId={yieldInputs.practiceId}
							isCropInfo={true}
							tooltip={yieldTooltip}
						/>

						<ComponentInputBox
							name="Seeds Cost/Acre ($)"
							units={{ benchmark: "$", current: "$" }}
							parentCallBack={setSeedCostInputs}
							selBenchmarkVal={seedCostInputs.benchmark}
							selCurrentVal={seedCostInputs.current}
							selPracticeId={seedCostInputs.practiceId}
							isCropInfo={true}
							tooltip={seedsTooltip}
						/>
					</div>
					<div className={classes.sameRowComponents}>
						<ComponentInputBox
							name="Herbicide Cost/Acre ($)"
							units={{ benchmark: "$", current: "$" }}
							parentCallBack={setHerbicideCostInputs}
							selBenchmarkVal={herbicideCostInputs.benchmark}
							selCurrentVal={herbicideCostInputs.current}
							selPracticeId={herbicideCostInputs.practiceId}
							isCropInfo={true}
							tooltip={herbicideTooltip}
						/>

						<ComponentInputBox
							name="Insecticide Cost/Acre ($)"
							units={{ benchmark: "$", current: "$" }}
							parentCallBack={setInsecticideCostInputs}
							selBenchmarkVal={insecticideCostInputs.benchmark}
							selCurrentVal={insecticideCostInputs.current}
							selPracticeId={insecticideCostInputs.practiceId}
							isCropInfo={true}
							tooltip={insecticideTooltip}
						/>

						<ComponentInputBox
							name="Fungicide Cost/Acre ($)"
							units={{ benchmark: "$", current: "$" }}
							parentCallBack={setFungicideCostInputs}
							selBenchmarkVal={fungicideCostInputs.benchmark}
							selCurrentVal={fungicideCostInputs.current}
							selPracticeId={fungicideCostInputs.practiceId}
							isCropInfo={true}
							tooltip={fungicideTooltip}
						/>
					</div>

					<Divider />

					<div className="sectionBanner">
						Indicate changes in tillage associated ONLY with soil health
						practices. Both leaving the field blank or entering a zero indicate
						a zero value. Include ALL equipment and passes needed for crop
						establishment.
						{/* Click
				the SAVE button at bottom of the page before moving to a different tab. */}
					</div>

					<div className={classes.sameRowComponents}>
						<div className="tillageBox">
							<div className="boxTextWithBeforeAfterTags">
								<span>Tillage</span>
								<span className="boxTooltip">
									<ToolTip
										title={
											<span style={{ fontSize: "0.8rem" }}>
												{" "}
												{tillageTooltip}
											</span>
										}
										enterTouchDelay={50}
									>
										<IconButton className="boxIconButton">
											<Help color="inherit" className="boxIcon" />
										</IconButton>
									</ToolTip>
								</span>
							</div>
							<div className="benchmarkTag"> Before </div>
							<Tillage
								idx={0}
								selectedId={benchmarkTillages[0].id}
								selectedPasses={benchmarkTillages[0].passes}
								tillageOptions={tillageOptions}
								parentCallBack={handleBenchmarkTillage}
							/>
							<Divider className={classes.divider} />
							<div className="currentTag"> After </div>
							<Tillage
								idx={0}
								selectedId={currTillages[0].id}
								selectedPasses={currTillages[0].passes}
								tillageOptions={tillageOptions}
								parentCallBack={handleCurrTillage}
							/>
						</div>

						<div className="tillageBox">
							<span className="boxTextWithoutTooltip">Tillage</span>
							<div className="benchmarkTag"> Before </div>
							<Tillage
								idx={1}
								selectedId={benchmarkTillages[1].id}
								selectedPasses={benchmarkTillages[1].passes}
								tillageOptions={tillageOptions}
								parentCallBack={handleBenchmarkTillage}
							/>
							<Divider className={classes.divider} />
							<div className="currentTag"> After </div>
							<Tillage
								idx={1}
								selectedId={currTillages[1].id}
								selectedPasses={currTillages[1].passes}
								tillageOptions={tillageOptions}
								parentCallBack={handleCurrTillage}
							/>
						</div>

						<div className="tillageBox">
							<span className="boxTextWithoutTooltip">Tillage</span>
							<div className="benchmarkTag"> Before </div>
							<Tillage
								idx={2}
								selectedId={benchmarkTillages[2].id}
								selectedPasses={benchmarkTillages[2].passes}
								tillageOptions={tillageOptions}
								parentCallBack={handleBenchmarkTillage}
							/>
							<Divider className={classes.divider} />
							<div className="currentTag"> After </div>
							<Tillage
								idx={2}
								selectedId={currTillages[2].id}
								selectedPasses={currTillages[2].passes}
								tillageOptions={tillageOptions}
								parentCallBack={handleCurrTillage}
							/>
						</div>
					</div>

					<Divider />

					<div className="sectionBanner">
						Indicate changes in costs associated ONLY with soil health
						practices. Select "None" in the "Fertilizer" box for either "Before"
						or "After" if no fertilizer is being applied.
						{/* Click the SAVE button at bottom of the
				page before moving to a different tab. */}
					</div>

					<div className={classes.checkboxContainer}>
						<FormControlLabel
							control={
								<Checkbox
									checked={overridePrices}
									onChange={() => setOverridePrices(!overridePrices)}
									color="primary"
								/>
							}
							label="Specify fertilizer prices"
						/>
					</div>

					{/*Try to initialize the Fertilizer & Tillages dynamically from single place, so there is no need to copy
			the same components 3 times */}
					<div className={classes.sameRowComponents}>
						<div className="fertilizerBox">
							<div className="boxTextWithBeforeAfterTags">
								<span>Nutrient Management/Acre</span>
								<span className="boxTooltip">
									<ToolTip
										title={
											<span style={{ fontSize: "0.8rem" }}>
												{" "}
												{fertilizerTooltip}
											</span>
										}
										enterTouchDelay={50}
									>
										<IconButton className="boxIconButton">
											<Help color="inherit" className="boxIcon" />
										</IconButton>
									</ToolTip>
								</span>
							</div>
							<div className="benchmarkTag"> Before </div>
							<Fertilizer
								idx={0}
								applicatorOptions={fertilizerApplOptions}
								productOptions={fertilizerProductOptions}
								selectedId={benchmarkFertilizers[0].applicatorId}
								selectedProducts={benchmarkFertilizers[0].products}
								overridePrices={overridePrices}
								parentCallBack={handleBenchmarkFertilizer}
							/>
							<Divider className={classes.divider} />
							<div className="currentTag"> After </div>
							<Fertilizer
								idx={0}
								applicatorOptions={fertilizerApplOptions}
								productOptions={fertilizerProductOptions}
								selectedId={currFertilizers[0].applicatorId}
								selectedProducts={currFertilizers[0].products}
								overridePrices={overridePrices}
								parentCallBack={handleCurrFertilizer}
							/>
						</div>

						<div className="fertilizerBox">
							<span className="boxTextWithoutTooltip">
								Nutrient Management/Acre
							</span>
							<div className="benchmarkTag"> Before </div>
							<Fertilizer
								idx={1}
								applicatorOptions={fertilizerApplOptions}
								productOptions={fertilizerProductOptions}
								selectedId={benchmarkFertilizers[1].applicatorId}
								selectedProducts={benchmarkFertilizers[1].products}
								overridePrices={overridePrices}
								parentCallBack={handleBenchmarkFertilizer}
							/>
							<Divider className={classes.divider} />
							<div className="currentTag"> After </div>
							<Fertilizer
								idx={1}
								applicatorOptions={fertilizerApplOptions}
								productOptions={fertilizerProductOptions}
								selectedId={currFertilizers[1].applicatorId}
								selectedProducts={currFertilizers[1].products}
								overridePrices={overridePrices}
								parentCallBack={handleCurrFertilizer}
							/>
						</div>

						<div className="fertilizerBox">
							<span className="boxTextWithoutTooltip">
								Nutrient Management/Acre
							</span>
							<div className="benchmarkTag"> Before </div>
							<Fertilizer
								idx={2}
								applicatorOptions={fertilizerApplOptions}
								productOptions={fertilizerProductOptions}
								selectedId={benchmarkFertilizers[2].applicatorId}
								selectedProducts={benchmarkFertilizers[2].products}
								overridePrices={overridePrices}
								parentCallBack={handleBenchmarkFertilizer}
							/>
							<Divider className={classes.divider} />
							<div className="currentTag"> After </div>
							<Fertilizer
								idx={2}
								applicatorOptions={fertilizerApplOptions}
								productOptions={fertilizerProductOptions}
								selectedId={currFertilizers[2].applicatorId}
								selectedProducts={currFertilizers[2].products}
								overridePrices={overridePrices}
								parentCallBack={handleCurrFertilizer}
							/>
						</div>
					</div>

					<div
						style={{
							textAlign: "left",
							fontWeight: "bolder",
							fontSize: "18px",
							marginLeft: "30px",
							marginRight: "30px",
							marginTop: "5px",
						}}
					>
						{apiResponse === "success" ? (
							<span style={{ color: "green" }}> {userMessage}</span>
						) : (
							<span style={{ color: "orange" }}> {userMessage} </span>
						)}
					</div>

					<div className={classes.buttonContainer}>
						<Button
							id="cropsubmit"
							variant="contained"
							color="primary"
							size="large"
							className={classes.button}
							onClick={handleSubmit}
						>
							Save
						</Button>
						{/*&nbsp;&nbsp;&nbsp;*/}
						{/*<Button variant="contained" color="primary" size="large" className={classes.button}> Reset </Button>*/}
					</div>
				</div>
			)}
		</>
	);
};

export default CropHistory;
